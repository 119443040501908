import { QueryClient } from 'react-query';
import { getAccessToken } from 'tokens';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!getAccessToken(),
    },
  },
});

export default queryClient;
