interface Palette {
  primary: string;
  supporting: string;
  accent: string;
  grey: string;
  dark: string;
  light: string;
}

interface FontSize {
  larger: number;
  large: number;
  base: number;
  small: number;
  smaller: number;
}
interface Typography {
  fontSize: FontSize;
}

interface Breakpoints {
  sm: number;
  md: number;
  lg: number;
}
interface Spacing {
  large: number;
  base: number;
  small: number;
}
interface Shadow {
  large: string;
  base: string;
}
interface Custom {
  navigationBarHeight: number;
  resultAlbumHeight: number;
}
interface Theme {
  palette: Palette;
  typography: Typography;
  breakpoints: Breakpoints;
  spacing: Spacing;
  shadow: Shadow;
  custom: Custom;
}

const theme: Theme = {
  palette: {
    primary: '#1DB954',
    supporting: '#138b3e',
    accent: '#E00000',
    grey: '#ececec',
    dark: '#000000',
    light: '#FFFFFF',
  },
  typography: {
    fontSize: {
      larger: 28,
      large: 16,
      base: 12,
      small: 8,
      smaller: 6,
    },
  },
  breakpoints: {
    sm: 768,
    md: 996,
    lg: 1200,
  },
  spacing: {
    large: 20,
    base: 10,
    small: 5,
  },
  shadow: {
    large: `0 4px 12px -2px rgb(0 0 0 / 30%)`,
    base: `0 1px 3px 0 rgba(0, 0, 0, 0.1)`,
  },
  custom: {
    navigationBarHeight: 80,
    resultAlbumHeight: 230,
  },
};

export type { Theme, Palette, Typography, Breakpoints };
export { theme };
