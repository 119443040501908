import ProtectedRoute from 'components/ProtectedRoute';
import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Path from './paths';

const Home = React.lazy(
  () => import('./home' /* webpackChunkName: "home", webpackPreload: true */),
);

const Login = React.lazy(
  () => import('./login' /* webpackChunkName: "login", webpackPreload: true */),
);

const Callback = React.lazy(
  () =>
    import('./callback' /* webpackChunkName: "home", webpackPreload: true */),
);

const Profile = React.lazy(
  () =>
    import('./profile' /* webpackChunkName: "profile", webpackPreload: true */),
);

const NotFound = React.lazy(
  () => import('./not-found' /* webpackChunkName: "not-found" */),
);

const Router = () => (
  <Suspense fallback={null}>
    <Switch>
      <ProtectedRoute exact path={Path.Root} component={Home} />
      <ProtectedRoute exact path={Path.Profile} component={Profile} />
      <Route exact path={Path.Login} component={Login} />
      <Route exact path={Path.Callback} component={Callback} />
      <Route>
        <NotFound />
      </Route>
    </Switch>
  </Suspense>
);

export default Router;
