import { addToUserSavedAlbums, removeFromUserSavedAlbums } from 'api';
import { useUserSavedAlbums } from 'queries/saved-albums';
import { Album, List } from 'types';

export interface UserAlbumState {
  albums: List<{ album: Album }> | undefined;
  loading: boolean;
  toggle: (albumId: string) => Promise<void>;
  hasAlbum: (albumId: string) => boolean;
}

function useUserAlbums(): UserAlbumState {
  const albums = useUserSavedAlbums();
  const toggle = async (albumId: string) => {
    hasAlbum(albumId)
      ? await removeFromUserSavedAlbums([albumId])
      : await addToUserSavedAlbums([albumId]);
    albums.refetch();
  };
  const hasAlbum = (albumId: string) =>
    !!albums.data?.items.find((item) => item.album.id === albumId);

  return { albums: albums.data, loading: albums.isLoading, toggle, hasAlbum };
}

export default useUserAlbums;
