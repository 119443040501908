import { searchArtists, getAlbumsPerArtist } from 'api';
import queryClient from 'queryClient';
import { useQuery } from 'react-query';
import { ExtendedArtist, List } from 'types';

async function searchArtistsWithAlbums(
  query: string,
): Promise<List<ExtendedArtist>> {
  // Get artists
  const artists = await searchArtists(query);

  // Get albums for each artist
  const artistsWithAlbums = await Promise.all(
    artists.items.map(async (artist) => {
      const albums = await getAlbumsPerArtist(artist.id);
      return { ...artist, albums: albums?.items };
    }),
  );
  return { ...artists, items: artistsWithAlbums };
}

export function clearSearch() {
  queryClient.resetQueries(['search']);
}

export function useSearchArtists(query: string) {
  return useQuery(['search', query], () => searchArtistsWithAlbums(query), {
    enabled: !!query,
    onSuccess: (data) => {
      // Make search results available under 'search' key
      // regardless of the 'query' value
      queryClient.setQueryData('search', data);
    },
  });
}
