import {
  nextPlayback,
  pausePlayback,
  previousPlayback,
  resumePlayback,
  startPlayback,
} from 'api';
import { usePlayerQuery } from 'queries/player';
import { Player } from 'types';

export interface PlayerState {
  playback: Player | undefined;
  play: (albumUri: string) => Promise<void>;
  pause: () => Promise<void>;
  resume: () => Promise<void>;
  next: () => Promise<void>;
  previous: () => Promise<void>;
  isPlaying: (id: string) => boolean;
  loading: boolean;
}

function usePlayer(): PlayerState {
  const playback = usePlayerQuery();
  const play = async (albumUri: string) => {
    await startPlayback(albumUri);
    playback.refetch();
  };
  const pause = async () => {
    await pausePlayback();
    playback.refetch();
  };
  const resume = async () => {
    await resumePlayback();
    playback.refetch();
  };
  const next = async () => {
    await nextPlayback();
    playback.refetch();
  };
  const previous = async () => {
    await previousPlayback();
    playback.refetch();
  };
  const isPlaying = (id: string) => playback.data?.item.album.id === id;
  return {
    playback: playback.data,
    play,
    pause,
    resume,
    next,
    previous,
    isPlaying,
    loading: playback.isLoading,
  };
}

export default usePlayer;
