import usePlayer from 'hooks/player';
import { PlayerState } from 'hooks/player/player';
import useUserAlbums from 'hooks/user-albums';
import { UserAlbumState } from 'hooks/user-albums/user-albums';
import useUser from 'hooks/user';
import { UserState } from 'hooks/user/user';
import useSearch, { SearchState } from 'hooks/search/search';

export interface StoreState {
  user: UserState;
  search: SearchState;
  player: PlayerState;
  userAlbums: UserAlbumState;
}

function useStoreHook(): StoreState {
  const user = useUser();
  const userAlbums = useUserAlbums();
  const player = usePlayer();
  const search = useSearch();
  return { user, userAlbums, player, search };
}

export default useStoreHook;
