import { useUserQuery } from 'queries/user';
import { signOut as signOutFn } from 'tokens';
import { User } from 'types';

export interface UserState {
  user: User | undefined;
  loading: boolean;
  signOut: () => void;
}

function useUser(): UserState {
  const user = useUserQuery();
  const signOut = () => {
    signOutFn();
  };
  return { user: user.data, loading: user.isLoading, signOut };
}

export default useUser;
