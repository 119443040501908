import { getPlayer } from 'api';
import { useQuery } from 'react-query';

const CURRENTLY_PLAYING_REFRESH_INTERVAL = 15000;

export function usePlayerQuery() {
  return useQuery(['player'], getPlayer, {
    refetchInterval: CURRENTLY_PLAYING_REFRESH_INTERVAL,
  });
}
