enum Path {
  Root = '/',
  Profile = '/profile',
  Callback = '/callback',

  // generic routes
  Login = '/login',
}

export default Path;
