import * as React from 'react';
import { getAccessToken } from 'tokens';
import { Redirect, Route, RouteProps } from 'react-router';
import Paths from '../../pages/paths';

function ProtectedRoute({ component: Component, ...rest }: RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return getAccessToken() && Component ? (
          <Component {...props} />
        ) : (
          <Redirect to={Paths.Login} {...props} />
        );
      }}
    />
  );
}

export default ProtectedRoute;
