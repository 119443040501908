import { fetchTokens, refreshToken } from 'api';
import Path from 'pages/paths';

const LOCAL_STORAGE_KEY = 'app.token';
const EXPIRED_TOKEN_MESSAGE = 'The access token expired';
export interface TokenData {
  accessToken: string;
  refreshToken: string;
}

async function fetchTokenStart(code: string) {
  // Fetch Tokens
  const token = await fetchTokens(code);

  // Set tokens locally
  setTokenData(token.data);
}

async function refreshTokenStart() {
  // Fetch Tokens
  const _refreshToken = getRefreshToken();
  const accessToken = await refreshToken(_refreshToken);

  // Set tokens locally
  setTokenData({ ...accessToken.data, refreshToken: _refreshToken });
}

function setTokenData(data: TokenData) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
}

function getTokenData(): TokenData {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
}

function getAccessToken(): string {
  return getTokenData().accessToken;
}

function getRefreshToken(): string {
  return getTokenData().refreshToken;
}

export function clearTokenData() {
  localStorage.setItem(LOCAL_STORAGE_KEY, '');
}

async function handelUnauthorizedResponse(error?: any) {
  const message = error?.response.data?.error.message;
  // Handle Expired token flow
  if (message === EXPIRED_TOKEN_MESSAGE) {
    await refreshTokenStart();
    return;
  }

  // Handle any other unauthorized cases
  clearTokenData();
  redirectToAuthPage();
}

const redirectToAuthPage = () => {
  window.location.assign(Path.Login);
};

const signOut = () => {
  clearTokenData();
  redirectToAuthPage();
};

export {
  fetchTokenStart,
  getTokenData,
  getAccessToken,
  handelUnauthorizedResponse,
  redirectToAuthPage,
  signOut,
};
