import { TokenData } from 'tokens';
import { Album, Artist, List, Player, User } from 'types';
import client from './api-client';

const MAX_PAGE_LIMIT_ALLOWED = 50;
interface Pagination {
  limit?: number;
  offset?: number;
}

function fetchTokens(code: string) {
  return client.get<TokenData>('/callback', { params: { code } });
}

function refreshToken(refresh_token: string) {
  return client.get<TokenData>('/refresh_token', { params: { refresh_token } });
}

async function getUserProfile() {
  const response = await client.get<any>('/v1/me');
  return response.data as User;
}

async function searchArtists(q: string) {
  const config = {
    params: { q, type: 'artist' },
  };
  const response = await client.get('/v1/search', config);
  return response.data.artists as List<Artist>;
}

async function getAlbumsPerArtist(artistId: string) {
  const response = await client.get(`v1/artists/${artistId}/albums`);
  return response.data as List<Album>;
}

async function getUserSavedAlbums({
  limit = MAX_PAGE_LIMIT_ALLOWED,
  offset = 0,
}: Pagination) {
  const config = {
    params: { limit, offset },
  };
  const response = await client.get('v1/me/albums', config);
  return response.data as List<{ album: Album }>;
}

async function addToUserSavedAlbums(ids: string[]) {
  return await client.put(`v1/me/albums?ids=${ids.join()}`);
}

async function removeFromUserSavedAlbums(ids: string[]) {
  return await client.delete(`v1/me/albums?ids=${ids.join()}`);
}

async function getPlayer() {
  const response = await client.get(`v1/me/player`);
  return response?.data as Player;
}

async function startPlayback(albumUri: string) {
  const data = {
    context_uri: albumUri,
  };
  return await client.put(`v1/me/player/play`, data);
}

async function resumePlayback() {
  return await client.put(`v1/me/player/play`);
}

async function pausePlayback() {
  return await client.put(`v1/me/player/pause`);
}

async function nextPlayback() {
  return await client.post(`v1/me/player/next`);
}

async function previousPlayback() {
  return await client.post(`v1/me/player/previous`);
}

export {
  fetchTokens,
  refreshToken,
  getUserProfile,
  searchArtists,
  getAlbumsPerArtist,
  getUserSavedAlbums,
  addToUserSavedAlbums,
  removeFromUserSavedAlbums,
  getPlayer,
  startPlayback,
  resumePlayback,
  pausePlayback,
  nextPlayback,
  previousPlayback,
};
