import { useSearchArtists } from 'queries/search';
import queryClient from 'queryClient';
import { useState } from 'react';
import { Album, ExtendedArtist, List } from 'types';

export interface SearchState {
  results: Album[] | undefined;
  rawResults: List<ExtendedArtist> | undefined;
  loading: boolean;
  query: string;
  clear: () => void;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

function useSearch(): SearchState {
  const [query, setQuery] = useState('');
  const { data, isLoading } = useSearchArtists(query);
  const clear = () => {
    queryClient.setQueryData(['search', query], undefined);
    setQuery('');
  };
  return {
    query,
    setQuery,
    loading: isLoading,
    rawResults: data,
    results: data?.items.flatMap((item) => item.albums),
    clear,
  };
}

export default useSearch;
